<template>
  <vue-final-modal class="modal-tournament-rules">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getLabel('rulesModal.title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('tournament-rules')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-tournament-rules__title">{{ props.tournamentContent?.name }}</div>
      <div class="modal-tournament-rules__body">
        <div class="body-item">
          {{ getLabel('main.prizePool') }}
          <span class="body-item__value">{{ props.tournamentContent?.prizePool }}</span>
        </div>
        <div class="body-item">
          {{ getLabel('main.minBet') }}
          <span class="body-item__value">{{ props.tournamentContent?.minBet }}</span>
        </div>
        <div class="body-item">
          <template v-if="dayjs().isBefore(props.tournamentData.startAt)">
            {{ getLabel('main.startsInLabel') }}
            <atomic-timer class="body-item__value" :expiredAt="props.tournamentData.startAt" size="md" />
          </template>
          <template v-else>
            {{ getLabel('main.endingInLabel') }}
            <atomic-timer class="body-item__value" :expiredAt="props.tournamentData.endAt" size="md" />
          </template>
        </div>
      </div>
      <div
        class="marked-content"
        v-html="
          DOMPurify.sanitize(marked.parse(tournamentContent?.termsText || '') as string, { FORBID_TAGS: ['style'] })
        "
      />
    </div>
    <div class="vfm__footer">
      <button-base
        class="modal-tournament-rules__button"
        type="secondary-1"
        size="md"
        @click="closeModal('tournament-rules')"
      >
        {{ getLabel('rulesModal.buttonLabel') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CITournamentCommon, CITournamentPage, ITournament } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    tournamentData: ITournament;
    currentLocaleCommonContent: Maybe<CITournamentCommon>;
    defaultLocaleCommonContent: Maybe<CITournamentCommon>;
    tournamentContent: Maybe<CITournamentPage>;
  }>();

  const dayjs = useDayjs();
  const { getContent } = useProjectMethods();

  const getLabel = (path: string): string =>
    getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, path);

  const { closeModal } = useModalStore();
</script>

<style src="~/assets/styles/components/modal/tournament-rules.scss" lang="scss" />
